import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import API from "@aws-amplify/api";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import React, { useState } from "react";
import { Dimensions } from "react-native";
import { makeVar } from "react-reactive-var";
import envConfig from "./envConfig";
import "./i18n.config";
import "./index.css";
import { default as AppConfig, screenResponsiveWidth } from "./src/Constants";
import MainViewContainer from "./src/MainViewContainer";
import appReducer from "./src/Redux/appReducer";
import initialState from "./src/Redux/initialState";
import Routes from "./src/Router/routes";
import config from "./src/Auth/aws-exports";
import { useFonts } from "@expo-google-fonts/open-sans";
import "highlight.js/styles/github.css";

export const AppRootContext = React.createContext({});
export const showSideMenu = makeVar(
  Dimensions.get("window").width > screenResponsiveWidth
);
export const forcedClosed = makeVar(false);
export const pinnedSideMenu = makeVar(false);
export const currentUserId = makeVar(null);
export const isLargeScreen = makeVar(true);
export const isUserDelinquent = makeVar(false);
export const isUserOnTrial = makeVar(false);
export const isFirstTrialStarted = makeVar(false);
export const isUserWithNoPaymentMethod = makeVar(false);
export const isUserEnabledToOperate = makeVar(false);
export const isBillingAddressSet = makeVar(false);
export const key_id = makeVar(null);
API.configure(config);
const App = () => {
  isLargeScreen(Dimensions.get("window").width > screenResponsiveWidth);
  let [fontsLoaded] = useFonts({
    normalFont: require("./src/Fonts/Gilroy-Regular.woff2"),
    boldFont: require("./src/Fonts/Gilroy-Bold.woff2"),
  });
  return (
    <AppRootContext.Provider value={{}}>
      <AppTheme.ThemeManager defaultColor={"light"}>
        <AppToast.ToastProvider
          {...AppConfig.notificationConfig}
          // offset for both top and bottom toasts
        >
          <AppStateManager.AppProvider
            initialState={initialState}
            appReducer={appReducer}
            envConfig={envConfig}
          >
            <MainViewContainer>
              <Routes />
            </MainViewContainer>

            {/*</ThemeProvider>*/}
          </AppStateManager.AppProvider>
        </AppToast.ToastProvider>
      </AppTheme.ThemeManager>
    </AppRootContext.Provider>
  );
};

//export default withAuthenticator(App);
export default App;
