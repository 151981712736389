import {
  REACT_APP_ENV,
  // REACT_APP_USERS_API,
  REACT_APP_aws_user_pools_id,
  REACT_APP_aws_user_pools_web_client_id,
  REACT_APP_aws_cognito_identity_pool_id,
  REACT_APP_aws_cognito_domain,
  REACT_APP_redirect_signin_uri,
  REACT_APP_redirect_signout_uri,
  // REACT_APP_GOOGLE_PLACES_KEY,
  REACT_APP_aws_region,
  REACT_APP_aws_appsync_region,
  REACT_APP_GRAPHQL_ENDPOINT,
  REACT_APP_WORKSPACE_COLLAB,
  REACT_APP_STRIPE_PK,
  REACT_APP_RTC_API,
  // REACT_APP_WORKSPACE_USERS,
  REACT_APP_REST_ENDPOINT,
  REACT_APP_SUPPORT_TOKEN,
  REACT_APP_AI_REST_ENDPOINT,
  REACT_APP_WEBSITE_TOKEN,
  REACT_APP_WEBSITE_WORKSPACE,
  REACT_APP_WEBSITE_AGENT,
  REACT_APP_GRAPHQL_TOKEN,
  REACT_APP_CONTENTFUL_TOKEN,
  REACT_APP_CONTENTFUL_SPACE,
  REACT_APP_CLOUD_FRONT_DISTRIBUTION,
  REACT_APP_ENCRYPTION_KEY,
} from "@env";

export default {
  REACT_APP_ENV,
  // REACT_APP_USERS_API,
  REACT_APP_aws_user_pools_id,
  REACT_APP_aws_user_pools_web_client_id,
  REACT_APP_aws_cognito_identity_pool_id,
  REACT_APP_aws_cognito_domain,
  REACT_APP_redirect_signin_uri,
  REACT_APP_redirect_signout_uri,
  // REACT_APP_GOOGLE_PLACES_KEY,
  REACT_APP_aws_region,
  REACT_APP_aws_appsync_region,
  REACT_APP_GRAPHQL_ENDPOINT,
  REACT_APP_WORKSPACE_COLLAB,
  REACT_APP_STRIPE_PK,
  REACT_APP_RTC_API,
  // REACT_APP_WORKSPACE_USERS,
  REACT_APP_REST_ENDPOINT,
  REACT_APP_SUPPORT_TOKEN,
  REACT_APP_AI_REST_ENDPOINT,
  REACT_APP_WEBSITE_TOKEN,
  REACT_APP_WEBSITE_WORKSPACE,
  REACT_APP_WEBSITE_AGENT,
  REACT_APP_GRAPHQL_TOKEN,
  REACT_APP_CONTENTFUL_TOKEN,
  REACT_APP_CONTENTFUL_SPACE,
  REACT_APP_CLOUD_FRONT_DISTRIBUTION,
  REACT_APP_ENCRYPTION_KEY,
};
