const appReducer = (state, action) => {
  switch (action.type) {
    case "SET_PAGE_NOT_FOUND": {
      return { ...state, pageNotFound: action.payload };
    }
    case "SET_CONV_LANG": {
      return { ...state, conv_lang: action.payload };
    }
    case "SET_CHAT_ID": {
      return { ...state, chatId: action.payload };
    }
    case "SET_WORKSPACE_ID": {
      return { ...state, workspaceId: action.payload };
    }
    case "SET_AGENT_ID": {
      return { ...state, agentId: action.payload };
    }
    case "SET_CHAT_OPEN": {
      return { ...state, isChatOpen: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default appReducer;
