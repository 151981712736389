import CryptoJS from "crypto-js";
import envConfig from "../../../envConfig";

const objectCleaner = (obj) => {
  // clean object from empty strings , nulls and empty arrays
  Object.keys(obj).forEach((key) => {
    if (
      obj[key] === "" ||
      (typeof obj[key] === "string" && obj[key]?.trim() === "")
    )
      delete obj[key];
    if (obj[key] === null) delete obj[key];
    if (Array.isArray(obj[key]) && obj[key].length === 0) delete obj[key];
  });
  return obj;
};

function decrypt(text) {
  const SECRET_KEY = CryptoJS.enc.Hex.parse(envConfig.REACT_APP_ENCRYPTION_KEY);
  let textParts = text.split(":");
  let iv = CryptoJS.enc.Hex.parse(textParts.shift());
  let encryptedText = CryptoJS.enc.Hex.parse(textParts.join(":"));
  let decipher = CryptoJS.AES.decrypt(
    { ciphertext: encryptedText },
    SECRET_KEY,
    { iv: iv }
  );
  return decipher.toString(CryptoJS.enc.Utf8);
}

export default {
  objectCleaner,
  decrypt,
};
