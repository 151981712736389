import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { useEffect } from "react";
import { TouchableOpacity, View } from "react-native";
import { Link } from "../../Router";

const PageNotFound = () => {
  const { currentTheme } = AppTheme.useTheme();
  const { dispatch } = AppStateManager.useAppStateManager();

  useEffect(() => {
    dispatch("SET_PAGE_NOT_FOUND", true);
  }, []);
  return (
    <div className="page-not-found">
      <AppPlaceholderView
        accessibilityLabel="doc-placeholder"
        size={60}
        wrapperStyle={{
          marginHorizontal: 0,
        }}
        icon={AppAssets.icons.networkError}
        mainCaption={"Agent is not available"}
        subCaption={"Please try again later"}
      />
    </div>
  );
};

export default PageNotFound;
