export const screenResponsiveWidth = 768;
export const appButtonHeight = 35;
export default {
  screenResponsiveWidth,
};

export const user = {
  _id: "1",
  name: "Human User",
  role: "user",
};
export const assistant = {
  _id: "2",
  name: "Assistant",
  role: "assistant",
};
