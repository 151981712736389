import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import "../index.css";
import Constants from "./Constants";
import usePath from "./Hooks/usePath";
import { isLargeScreen } from "./MainViewContainer";

const MainViewScreen = ({ children, height, sideMenuOpen, width }) => {
  const { state } = AppStateManager.useAppStateManager();
  const { currentTheme } = AppTheme.useTheme();
  const { path, navigateTo } = usePath();

  const _styles = stylesGenerator({
    height,
    sideMenuOpen,
    width,
    pageNotFound: state?.pageNotFound,
    isLargeScreen: isLargeScreen(),
    currentTheme,
    isChatOpen: state?.isChatOpen,
  });
  const { t } = useTranslation();

  return <View style={_styles.content}>{children}</View>;
};

const stylesGenerator = ({
  height,
  sideMenuOpen,
  width,
  pageNotFound,
  isLargeScreen,
  currentTheme,
  isChatOpen,
}) =>
  StyleSheet.create({
    content: {
      zIndex: -1,
      backgroundColor: !isChatOpen && currentTheme?.theme?.transparent,
      height: "100%",
      width: "100%",
      maxWidth:
        !pageNotFound &&
        width - (sideMenuOpen && isLargeScreen && Constants.sideMenuWidth),
      flexDirection: "column",
    },
  });

export default MainViewScreen;
