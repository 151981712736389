import apiClient from "@toothfairy/shared-api/apiClient";
import secureRequest from "@toothfairy/shared-api/secureAPI";
import envConfig from "../../../envConfig";
import { key_id } from "../../../App";
import Common from "../Common";

const downloadUrlGeneration = async ({
  workspaceid,
  logId,
  type,
  filename,
  context = "pdf",
}) => {
  const headers = { "x-api-key": Common.decrypt(key_id()) };
  const url = `${envConfig.REACT_APP_REST_ENDPOINT}/documents/requestDownloadURLIncognito?filename=${filename}&type=${type}&workspaceid=${workspaceid}&logId=${logId}&context=${context}`;
  return secureRequest(
    apiClient.apiClient,
    "GET",
    url,
    null,
    null,
    false,
    headers
  );
};

export default {
  downloadUrlGeneration,
};
