import { useEffect, useState } from "react";
import { matchPath } from "react-router";
import { useHistory, useLocation } from "../Router";
import { routes } from "../Router/routes";

const usePath = () => {
  const location = useLocation();
  const history = useHistory();
  const [params, setParams] = useState({});
  const [path, setPath] = useState({});
  const navigateTo = (path) => {
    history.push(path);
  };

  useEffect(() => {
    if (location?.pathname) {
      for (const [_, path] of Object.entries(routes)) {
        const match = matchPath(location?.pathname, {
          path,
          exact: true,
        });
        if (match?.isExact) {
          setParams(match.params);
          setPath(match);
          break;
        }
      }
    }
  }, [location.pathname]);

  return {
    path,
    params,
    history,
    location,
    navigateTo,
  };
};

export default usePath;
