import React from "react";

import AgentScreenContainer from "../Pages/Agent/AgentScreenContainer";
import { Route, Switch } from "./index";
import PageNotFound from "../Pages/PageNotFound/PageNotFoundScreen";

export const routes = {
  agent: "/:workspaceId/:agentId/:mode?",
};

const Routes = ({ currentView, setCurrentView }) => {
  return (
    <Switch>
      <Route path={routes.agent} exact component={AgentScreenContainer} />
      <Route component={PageNotFound} />
    </Switch>
  );
};

export default Routes;
