import API from "@aws-amplify/api";
import { getTokenByAgent } from "../../../../app/src/graphql/queries";
import envConfig from "../../../envConfig";

const getToken = async (agentId) => {
  return API.graphql({
    query: getTokenByAgent,
    variables: { id: agentId },
    authMode: "API_KEY",
    authToken: envConfig.REACT_APP_GRAPHQL_TOKEN,
  });
};

export default {
  getToken: getToken,
};
