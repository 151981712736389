import { AppText, AppIcon } from "@toothfairy/shared-ui";
import AppFadeView from "@toothfairy/shared-ui/AppFadeView";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { useEffect, useState } from "react";
const ToothFairyAIDisclosure = ({
  disclaimer = " ",
  showDarkMode,
  agentColor,
  showReload,
}) => {
  const { currentTheme, mode, setMode } = AppTheme.useTheme();
  const [text, setText] = useState("");
  useEffect(() => {
    if (disclaimer) {
      let currentText = "";
      const words = disclaimer?.split(" ");
      setTimeout(() => {
        const intervalId = setInterval(() => {
          if (words.length > 0) {
            currentText += words.shift() + " ";
            setText(currentText);
          } else {
            clearInterval(intervalId);
          }
        }, 1); // 8 seconds,
      }, 1);
    }

    // Cleanup function
  }, [disclaimer]); // Run once when component mounts

  return (
    <AppFadeView
      duration={200}
      style={{
        alignItems: "center",
        marginBottom: 15,
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      {showReload ? (
        <AppIcon
          onPress={() => {
            window.location.reload();
          }}
          icon={AppAssets?.icons?.reload}
          color={
            mode !== "blue"
              ? agentColor || currentTheme?.theme?.primary
              : currentTheme?.theme?.white
          }
          isClickable
          size={14}
          wrapperStyle={{
            cursor: "pointer",
            rotate: mode == "dark" && "180deg",
            marginLeft: 5,
          }}
        />
      ) : (
        <AppText></AppText>
      )}
      <AppText
        style={{
          fontSize: currentTheme?.theme?.extra_small_font_size,
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
        wrapperStyle={{
          paddingHorizontal: 10,
          maxWidth: "80%",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          verticalAlign: "bottom",
        }}
        // fontWeight="bold"
        color={currentTheme?.theme?.darkGrey}
      >
        {text}
      </AppText>
      {showDarkMode &&
        (mode !== "blue" ? (
          <AppIcon
            onPress={() => {
              setMode(mode == "dark" ? "light" : "dark");
            }}
            icon={
              mode == "dark"
                ? AppAssets?.icons?.theme_adjust
                : AppAssets?.icons?.theme_adjust
            }
            color={agentColor || currentTheme?.theme?.primary}
            isClickable
            size={16}
            wrapperStyle={{
              cursor: "pointer",
              rotate: mode == "dark" && "180deg",
              marginRight: 5,
            }}
          />
        ) : (
          <AppText></AppText>
        ))}
    </AppFadeView>
  );
};

export default ToothFairyAIDisclosure;
