import API, { graphqlOperation } from "@aws-amplify/api";
import useAPI from "@toothfairy/shared-api/useApi";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppToast from "@toothfairy/shared-ui/AppToast";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { Suspense, useEffect, useState } from "react";
import {
  onCreateChatMessageByChatIDEvent,
  onUpdateChatMessageByChatIDEvent,
} from "../../../../app/src/graphql/subscriptions";
import { key_id } from "../../../App";
import envConfig from "../../../envConfig";
import chatsHelper from "../../Api/Chats/chatsHelper";
import Chatter from "../../Api/Chatter";
import Common from "../../Api/Common";
import Files from "../../Api/Files";
import Token from "../../Api/Token";
import workspaceHelper from "../../Api/Workspaces/workspaceHelper";
import { user } from "../../Constants";
import { useHistory, useParams } from "../../Router";
import AgentScreen from "./AgentScreen";

const AgentScreenContainer = () => {
  const defaultPlaceholderMessageOnLoading = "**...**";
  const { agentId, workspaceId } = useParams();
  const history = useHistory();
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const AppToasty = AppToast.useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [requestedStop, setRequestedStop] = useState(false);
  const [isFirstMessage, setIsFirstMessage] = useState(true);
  const [workspace, setWorkspace] = useState(null);
  const [subscriptionObj, setSubscriptionObject] = useState(null);
  const [subscriptionObj2, setSubscriptionObject2] = useState(null);
  const [subscriptionObj3, setSubscriptionObject3] = useState(null);
  const [lastOptimisticId, setLastOptimisticId] = useState(null);
  const [allowStop, setAllowStop] = useState(true);
  const [isAIStreaming, setIsAIStreaming] = useState(false);
  const [isMessagesLoaded, setIsMessagesLoaded] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [messageIndex, setMessageIndex] = useState(1);
  const [inputText, setInputText] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loadingMessageId, setLoadingMessageId] = useState(null);
  const [agentData, setAgentData] = useState(null);
  const [encryptedToken_, setEncryptedToken_] = useState(null);
  const [agentImage, setAgentImage] = useState(null);
  const [agentLogo, setAgentLogo] = useState(null);
  const { mode } = AppTheme.useTheme();
  const cursor = mode === "light" ? "\u26AB" : "\u26AA";
  const {
    data: S3downloadUrlData,
    loading: urldownloadGenerationInProgress,
    apiRequest: S3downloadUrlRequest,
    response: S3downloadResponse,
  } = useAPI(Files.downloadUrlGeneration, envConfig);
  const {
    data: S3downloadUrlDataForWidget,
    loading: urldownloadGenerationInProgressForWidget,
    apiRequest: S3downloadUrlRequestForWidget,
    response: S3downloadResponseForWidget,
  } = useAPI(Files.downloadUrlGeneration, envConfig);
  const fetchAgentImage = async (path, isLargeLogo) => {
    const _url = await S3downloadUrlRequest({
      filename: `imported-image/${workspaceId}/${path}`,
      context: "pdf",
      workspaceid: workspaceId,
    });
    if (isLargeLogo) setAgentLogo(_url?.url);
    else setAgentImage(_url?.url);
  };

  const updateFavicon = (icoUrl) => {
    const link =
      document.querySelector("link[rel*='shortcut icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = icoUrl;
    document.getElementsByTagName("head")[0].appendChild(link);
  };

  useEffect(() => {
    if (agentData?.icon && workspaceId) {
      fetchAgentImage(agentData?.icon);
    } else {
      setAgentImage(null);
    }
  }, [agentData?.icon, workspaceId]);

  useEffect(() => {
    if (agentData?.icoUrl && workspaceId) {
      updateFavicon(agentData?.icoUrl);
    } else {
      updateFavicon("https://toothfairyai.com/favicon.ico");
    }
  }, [agentData?.icoUrl, workspaceId]);
  useEffect(() => {
    if (agentData?.largeLogo && workspaceId)
      fetchAgentImage(agentData?.largeLogo, true);
  }, [agentData?.largeLogo, workspaceId]);
  useEffect(() => {
    dispatch("SET_WORKSPACE_ID", workspaceId);
    dispatch("SET_AGENT_ID", agentId);
  }, [agentId, workspaceId]);
  const createChat = async () => {
    if (isFirstMessage) {
      try {
        const encryptedToken = await Token.getToken(agentId);
        setEncryptedToken_(encryptedToken?.data?.getTokenByAgent);
        key_id(encryptedToken?.data?.getTokenByAgent);
        const authToken = Common.decrypt(encryptedToken?.data?.getTokenByAgent);
        const [result, agent, workspace] = await Promise.all([
          chatsHelper.createChat(
            {
              primaryRole: agentId,
              workspaceID: workspaceId,
              allowMultipleRoles: false,
              creationTime: Math.floor(new Date().getTime() / 1000),
              visibility: "widget",
              name: "New Chat",
            },
            authToken
          ),
          chatsHelper.getAgent(agentId, authToken),
          workspaceHelper.getWorkspace(workspaceId, authToken),
        ]);
        if (
          agent?.data?.getAgent?.preventWidgetUsage === true ||
          agent?.data?.getAgent?.mode == "planner"
        ) {
          history.push("/error");
          return;
        }
        setWorkspace({
          id: workspace?.data?.getWorkspace?.id,
          customChartingConfig: JSON.parse(
            workspace?.data?.getWorkspace?.customChartingConfig
          ),
        });
        setAgentData(agent?.data?.getAgent);
        chatID = result?.data?.createChat?.id;
        // const result_initial_message = await chatsHelper.createChatMessage(
        //   {
        //     role: assistant,
        //     chatID,
        //     text: agent?.data?.getAgent?.messageOnLaunch || "",
        //     creationTime: Math.floor(new Date().getTime() / 1000),
        //   },
        //   authToken
        // );
        dispatch("SET_CHAT_ID", chatID);
        setIsFirstMessage(false);
      } catch (error) {
        history.push("/error");
        console.error(error);
      }
    }
  };
  function removeSpecificUnicodeAtEnd(text) {
    const unicodeRegex = /(\u26AB|\u26AA)$/g;
    return text.replace(unicodeRegex, "");
  }
  useEffect(() => {
    const updateMessage = () => {
      setMessages((prevMessages) => {
        let updatedMessages = [...prevMessages];
        updatedMessages[0]["text"] = agentData?.messagesOnLaunch[carouselIndex];
        return updatedMessages;
      });

      // Increment index or loop back to 0
      setCarouselIndex(
        (prevIndex) =>
          (prevIndex + 1) % (agentData?.messagesOnLaunch.length || 1)
      );
    };
    if (
      agentData?.messagesOnLaunch?.length > 0 &&
      agentData?.rotateMessagesOnLaunchInterval &&
      messages?.length == 1
    ) {
      const interval = setInterval(
        updateMessage,
        agentData?.rotateMessagesOnLaunchInterval || 5000
      );

      return () => {
        clearInterval(interval); // Cleanup interval on component unmount
      };
    }
  }, [agentData?.messagesOnLaunch?.length, carouselIndex, messages?.length]);
  const handleFeedback = async (id, humanFeedback) => {
    console.log(id, humanFeedback);
    try {
      const result = await chatsHelper.updateChatMessage(
        {
          id: id,
          humanFeedback: humanFeedback,
        },
        Common.decrypt(encryptedToken_)
      );
      setMessages((prevMessages) => {
        let updatedMessages = [...prevMessages];
        const newMessageIndex = updatedMessages.map((u) => u._id).indexOf(id);
        if (newMessageIndex !== -1) {
          updatedMessages[newMessageIndex] = {
            ...updatedMessages[newMessageIndex],
            humanFeedback: humanFeedback,
          };
        }
        return [...updatedMessages];
      });
      AppToasty.show("Feedback saved", {
        placement: "bottom",
        type: "success",
      });
    } catch (error) {
      console.log(error);
      AppToasty.show("Error saving feedback", {
        placement: "bottom",
        type: "danger",
      });
    }
  };
  useEffect(() => {
    if (isFirstMessage && agentId && workspaceId) createChat();
  }, [isFirstMessage, agentId, workspaceId]);
  const getActiveWorkspace = () => {
    return {
      id: state?.workspaceId,
      workspaceToken: envConfig.REACT_APP_WEBSITE_TOKEN,
    };
  };
  useEffect(() => {
    if (
      messages?.length === 0 &&
      agentData?.id &&
      agentData?.showSplashWhenEmpty !== true
    ) {
      const timer = setTimeout(() => {
        setMessages((prevMessages) => [
          {
            _id: 2,
            text:
              agentData?.messageOnLaunch ||
              "I'm a ToothFairyAI agent, how can I help you?",
            createdAt: new Date().getTime() / 1000,
            user: {
              name: "assistant",
              _id: 2,
              agentName: agentData?.label,
            },
          },
          ...prevMessages,
        ]);
        setMessageIndex((prevIndex) => prevIndex + 1);
      }, 50);

      return () => clearTimeout(timer);
    } else {
      setTimeout(() => {
        setIsMessagesLoaded(true);
      }, 10);
    }
  }, [
    agentData?.messageOnLaunch,
    agentData?.id,
    messageIndex,
    messages?.length,
  ]);

  useEffect(() => {
    let subscriptionObj;
    let subscriptionObj2;
    let subscriptionObj3;
    if (state.chatId) {
      subscriptionObj?.unsubscribe();
      subscriptionObj2?.unsubscribe();
      envConfig.REACT_APP_ENV == "dev" && console.log("_", "subscribing");
      subscriptionObj3 = API.graphql(
        graphqlOperation(onCreateChatMessageByChatIDEvent, {
          chatID: state.chatId,
        })
      ).subscribe({
        next: async (data) => {
          const mainMessageObj =
            data?.value?.data?.onCreateChatMessageByChatIDEvent;
          let _agent_ = null;
          const message = mainMessageObj?.text;
          const _agentId = mainMessageObj?.agentID;
          // ISSUE SINCE THE AGENT ID OF THE CREATED OBJECT IS ALWAYS THE PREVIOUS AGENT ID
          if (_agentId != agentId && _agentId != null) {
            const encryptedToken = await Token.getToken(agentId);
            const authToken = Common.decrypt(
              encryptedToken?.data?.getTokenByAgent
            );
            _agent_ = await chatsHelper.getAgent(_agentId, authToken);
            setAgentData(_agent_?.data?.getAgent);
          }
          const newMessage = {
            ...mainMessageObj,
            _id: mainMessageObj.id,
            createdAt: mainMessageObj.creationTime,
            user: {
              id: null,
              name: "assistant",
              agentName: agentData?.label,
            },
            text: `${message}`,
            loading: false,
            humanFeedback: mainMessageObj?.humanFeedback,
            humanFeedbackType: mainMessageObj?.humanFeedbackType,
            humanFeedbackSubType: mainMessageObj?.humanFeedbackSubType,
            humanFeedbackComment: mainMessageObj?.humanFeedbackComment,
            metadata:
              typeof mainMessageObj.metadata === "string"
                ? JSON.parse(mainMessageObj.metadata)
                : mainMessageObj.metadata,
            callbackMetadata:
              typeof mainMessageObj.callbackMetadata === "string"
                ? JSON.parse(mainMessageObj.callbackMetadata)
                : mainMessageObj.callbackMetadata,
          };
          setTimeout(() => {
            setMessages((prevMessages) => {
              let updatedMessages = [...prevMessages];
              if (updatedMessages?.find((u) => u._id == newMessage?._id))
                return updatedMessages;
              updatedMessages.push(newMessage);
              // find loading message and remove it
              const loadingMessageIndex =
                updatedMessages.indexOf(loadingMessageId);
              // find the last index of the loading message just by looking at the loading property

              const loadingMessageIndex2 = updatedMessages.findIndex(
                (u) => u.loading
              );
              if (loadingMessageIndex2 !== -1) {
                updatedMessages[loadingMessageIndex2] = {
                  ...updatedMessages[loadingMessageIndex2],
                  user: {
                    ...updatedMessages[loadingMessageIndex2].user,
                    agentName: _agent_?.data?.getAgent?.label,
                  },
                };
              }
              // sort messages by createdAt
              updatedMessages = updatedMessages.sort((a, b) => {
                return new Date(a.createdAt) - new Date(b.createdAt);
              });
              return [...updatedMessages];
            });
          }, 1000);
        },
      });
      subscriptionObj2 = API.graphql(
        graphqlOperation(onUpdateChatMessageByChatIDEvent, {
          chatID: state.chatId,
        })
      ).subscribe({
        next: async (data) => {
          const mainMessageObj =
            data?.value?.data?.onUpdateChatMessageByChatIDEvent;
          envConfig?.REACT_APP_ENV == "dev" &&
            console.log(
              "_",
              data?.value?.data?.onUpdateChatMessageByChatIDEvent,
              { isAIStreaming },
              { loadingMessageId }
            );

          const message =
            data?.value?.data?.onUpdateChatMessageByChatIDEvent?.text;
          if (
            mainMessageObj.status === "fulfilled" &&
            (mainMessageObj?.callbackMetadata?._type == "suggestion" ||
              mainMessageObj?.callbackMetadata?._type == "nextQuestion")
          ) {
            setMessages((prevMessages) => {
              let updatedMessages = [...prevMessages];
              const callbackMessageIndex = updatedMessages
                .map((u) => u._id)
                .indexOf(mainMessageObj?.id);
              if (callbackMessageIndex !== -1) {
                updatedMessages[callbackMessageIndex] = {
                  ...updatedMessages[callbackMessageIndex],
                  callbackMetadata: mainMessageObj.callbackMetadata,
                };
              }
              return [...updatedMessages];
            });
          }
          if (requestedStop) return;
          if (mainMessageObj.status === "fulfilled" && isAIStreaming) {
            envConfig.REACT_APP_ENV == "dev" && console.log("_", "fulfilled");
            if (loadingMessageId === mainMessageObj.id) {
              setIsAIStreaming(false);
              setLoadingMessageId(null);
            }
            setMessages((prevMessages) => {
              let updatedMessages = [...prevMessages];
              const loadingMessageIndex = updatedMessages
                .map((u) => u._id)
                .indexOf(mainMessageObj.id);
              if (loadingMessageIndex !== -1) {
                updatedMessages[loadingMessageIndex] = {
                  ...updatedMessages[loadingMessageIndex],
                  text: removeSpecificUnicodeAtEnd(message),
                  loading: false,
                  metadata:
                    typeof mainMessageObj.metadata === "string"
                      ? JSON.parse(mainMessageObj.metadata)
                      : mainMessageObj.metadata,
                  callbackMetadata:
                    typeof mainMessageObj.callbackMetadata === "string"
                      ? JSON.parse(mainMessageObj.callbackMetadata)
                      : mainMessageObj.callbackMetadata,
                };
              }
              return [...updatedMessages];
            });
          } else if (
            (mainMessageObj.text !== "**...**" &&
              mainMessageObj.text !== agentData?.placeholderMessageOnLoading &&
              mainMessageObj.isAI === true) ||
            (loadingMessageId == null && isAIStreaming)
          ) {
            envConfig.REACT_APP_ENV == "dev" &&
              console.log("loadingMessageId", mainMessageObj.id);
            // update the last message in the chat with role assistant to the new message
            setMessages((prevMessages) => {
              let updatedMessages = [...prevMessages];
              const loadingMessageIndex = updatedMessages
                .map((u) => u._id)
                .indexOf(mainMessageObj.id);
              if (loadingMessageIndex !== -1) {
                updatedMessages[loadingMessageIndex] = {
                  ...updatedMessages[loadingMessageIndex],
                  text: `${message}${isAIStreaming ? " " + cursor : ""}`,
                  loading: false,
                };
              }
              return [...updatedMessages];
            });
          }
        },
        error: (error) => {
          console.log(
            `Error with subscription: ${lastSubscribedChatId}`,
            error
          );
        },
      });
      setSubscriptionObject(subscriptionObj);
      setSubscriptionObject2(subscriptionObj2);
      setSubscriptionObject3(subscriptionObj3);
    }

    return () => {
      if (subscriptionObj) {
        envConfig.REACT_APP_ENV == "dev" && console.log("_", "unsubscribing");
        subscriptionObj.unsubscribe();
        subscriptionObj2.unsubscribe();
        subscriptionObj3.unsubscribe();
      }
    };
  }, [state?.chatId, loadingMessageId, isAIStreaming]);
  const onCancelGeneration = async () => {
    subscriptionObj?.unsubscribe();
    subscriptionObj2?.unsubscribe();
    subscriptionObj3?.unsubscribe();
    setRequestedStop(true);
    setAllowStop(false);
    setIsAIStreaming(false);
    setIsLoading(false);

    await chatsHelper.updateChat(
      state?.chatId,
      [
        {
          property: "isAIReplying",
          value: false,
        },
        {
          property: "activeMessageForAI",
          value: null,
        },
      ],
      Common.decrypt(encryptedToken_)
    );
    await chatsHelper.updateChatMessage(
      {
        id: loadingMessageId,
        status: "cancelled",
      },
      Common.decrypt(encryptedToken_)
    );
    setLoadingMessageId(null);
    setTimeout(() => {
      setAllowStop(true);
    }, [1000]);
  };
  let chatID;
  const fetchData = async (message) => {
    try {
      const optimisticMessageId = Math.random().toString(36).substring(7);
      setLastOptimisticId(optimisticMessageId);
      setMessages((previousMessages) => [
        ...previousMessages,
        {
          _id: optimisticMessageId,
          text: message,
          createdAt: new Date().getTime() / 1000,
          user: {
            name: "user",
          },
          loading: false,
        },
      ]);
      const promises = [
        chatsHelper.createChatMessage({
          chatID: state.chatId || chatID,
          text: message,
          role: "user",
          userID: user._id,
          isAI: false,
          creationTime: Math.floor(new Date().getTime() / 1000),
          workspaceID: workspaceId,
        }),
        chatsHelper.createChatMessage({
          chatID: state.chatId || chatID,
          text:
            agentData?.placeholderMessageOnLoading ||
            defaultPlaceholderMessageOnLoading,
          role: "assistant",
          isAI: true,
          creationTime: Math.floor(new Date().getTime() / 1000) + 1,
          workspaceID: workspaceId,
          agentID: agentData?.id,
        }),
      ];
      const [result1, assistantPlaceholder] = await Promise.all(promises);
      // update the messages again with the _id of the message from the server
      setMessages((previousMessages) => {
        const updatedMessages = [...previousMessages];
        // find index of the optimistic message
        const optimisticMessageIndex = updatedMessages
          .map((u) => u._id)
          .indexOf(optimisticMessageId);
        // replace the optimistic message with the message from the server
        updatedMessages[optimisticMessageIndex] = {
          ...updatedMessages[optimisticMessageIndex],
          _id: result1?.data?.createChatMessage?.id,
        };
        return updatedMessages;
      });
      // create a new Date one second in the future to ensure the message is at the bottom
      let f_date = new Date();
      f_date.setSeconds(f_date.getSeconds() + 1);
      setLoadingMessageId(assistantPlaceholder?.data?.createChatMessage?.id);
      setMessages((previousMessages) => [
        ...previousMessages,
        {
          _id: assistantPlaceholder?.data?.createChatMessage?.id,
          text: "",
          createdAt: f_date.getTime() / 1000,
          user: {
            _id: 2,
            name: "assistant",
            agentName: agentData?.label,
          },
          loading: true,
        },
      ]);
      setRequestedStop(false);
      setIsAIStreaming(true);
      setAllowStop(true);
      await Promise.all([
        chatsHelper.updateChat(
          state?.chatId || chatID,
          [
            {
              property: "isAIReplying",
              value: true,
            },
            {
              property: "activeMessageForAI",
              value: assistantPlaceholder?.data?.createChatMessage?.id,
            },
          ],
          Common.decrypt(encryptedToken_)
        ),
        Chatter.getAnswerinChat({
          chatid: state.chatId || chatID,
          messages: [
            {
              text: message,
              role: user?.username,
              userID: user?._id,
            },
          ],
          token: Common.decrypt(encryptedToken_),
          workspaceid: getActiveWorkspace()?.id,
          stream: "true",
          nextMessageId: assistantPlaceholder?.data?.createChatMessage?.id,
          agentid: agentData?.id,
        }),
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      // remove the loading message by checking the loading property
      // setMessages((previousMessages) =>
      //   previousMessages.filter((m) => !m.loading)
      // );
    }
  };
  // from messages get the last message from the assistant handling the case where there are no messages yet or no messages from the assistant
  const lastMessageFromAssistant =
    messages?.filter((u) => u?.user?.name === "assistant")?.slice(-1)[0] || {};

  useEffect(() => {
    if (
      messages?.filter((u) => u?.loading === true).length > 0 &&
      !isAIStreaming
    ) {
      // Update the last message's loading status and add the reply
      setMessages((previousMessages) => {
        const updatedMessages = previousMessages.slice();
        updatedMessages[0].loading = false;
        // updatedMessages[0].text = "Something went wrong, please try again";
        return updatedMessages;
      });
    }
  }, [messages?.length, isAIStreaming]);
  const handleSend = (message) => {
    if (message.trim().length === 0) return;
    setIsLoading(true);
    fetchData(message);
  };
  const onQuestionPress = (question) => {
    if (!isLoading) handleSend(question);
  };
  const onLanguageChange = (language) => {
    dispatch("SET_CONV_LANG", language);
  };

  // change the tab title to the agent title
  useEffect(() => {
    if (agentData?.label) document.title = agentData?.label;
  }, [agentData?.label]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {agentData?.id && urldownloadGenerationInProgress == false ? (
        <AgentScreen
          S3downloadUrlRequest={S3downloadUrlRequestForWidget}
          onQuestionPress={onQuestionPress}
          onLanguageChange={onLanguageChange}
          messages={messages}
          setMessages={setMessages}
          user={user}
          handleSend={handleSend}
          isMessagesLoaded={isMessagesLoaded}
          languages={state.languages}
          selectedLanguage={state.selectedLanguage}
          isAIStreaming={isAIStreaming}
          onCancel={onCancelGeneration}
          inputText={inputText}
          setInputText={setInputText}
          loadingMessageId={loadingMessageId}
          isLastMessageEmpty={lastMessageFromAssistant?.text == ""}
          allowFeedback={agentData?.allowFeedback}
          handleFeedback={handleFeedback}
          isLoading={isLoading}
          showAgentName={agentData?.showAgentName}
          placeholder={agentData?.placeholderInputMessage || "Ask me anything"}
          agentTitle={agentData?.label}
          textToDisplayWhileLoading={
            agentData?.placeholderMessageOnLoading ||
            defaultPlaceholderMessageOnLoading
          }
          showSplash={agentData?.showSplashWhenEmpty}
          splashTitle={agentData?.messageOnLaunch}
          coder={agentData?.mode === "coder"}
          isAgentFetched={agentData?.label != null}
          disclaimer={agentData?.disclaimer}
          agentImage={agentImage}
          agentLogo={agentLogo}
          agentColor={mode === "dark" ? agentData?.darkColor : agentData?.color}
          logoBackground={
            mode === "dark"
              ? agentData?.darkLogoBackground
              : agentData?.logoBackground
          }
          showTimeForResponse={agentData?.showTimeForResponse}
          showDetectedLanguage={agentData?.showDetectedLanguage}
          quickQuestions={agentData?.quickQuestions
            ?.split(";")
            ?.map((u) => u?.trim()?.replace(/\s+/g, " "))}
          allowStop={allowStop}
          workspaceId={workspaceId}
          workspace={workspace}
        />
      ) : (
        <div className="tf-loader"></div>
      )}
    </Suspense>
  );
};

export default AgentScreenContainer;
