import API from "@aws-amplify/api";

const simpliedGetWorkspace = /* GraphQL */ `
  query GetWorkspace($id: ID!) {
    getWorkspace(id: $id) {
      id
      customChartingConfig
    }
  }
`;

const getWorkspace = (id, authToken) =>
  API.graphql({
    query: simpliedGetWorkspace,
    variables: { id },
    authMode: "API_KEY",
    authToken,
  });

export default {
  getWorkspace,
};
