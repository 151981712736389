import AppBaseScreen from "@toothfairy/shared-ui/AppBaseScreen";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import dayjs from "dayjs";
import React, { Suspense, useEffect, useMemo, useState } from "react";
import { Platform, StyleSheet, View, useWindowDimensions } from "react-native";
import { makeVar } from "react-reactive-var";
import MainViewScreen from "./MainViewScreen";
import { DeepLinking, Router } from "./Router";

export const appDimensions = makeVar({ width: 0, height: 0 });
export const lastMembersChange = makeVar(dayjs().toDate().getTime());
export const globalAppConfig = makeVar({});
export const isLargeScreen = makeVar(true);
export const isMobileScreen = makeVar(false);
export const isLandscapeMobile = makeVar(false);
export const isVeryLargeScreen = makeVar(false);
export const isLargerScreen = makeVar(false);
export const minAppWidth = makeVar(365);
export const countryCode = makeVar("");

const MainViewContainer = ({ children, authUserEmail }) => {
  const { setMode, mode, currentTheme } = AppTheme.useTheme();
  const { height, width } = useWindowDimensions();
  const isInIframe = window.frameElement !== null;
  const { state } = AppStateManager.useAppStateManager();
  isLargeScreen(width > (globalAppConfig()?.screenResponsiveWidth || 768));
  isLargerScreen(
    width > (globalAppConfig()?.largerScreenResponsiveWidth || 1024)
  );
  isMobileScreen(width < (globalAppConfig()?.mobileResponsiveWidth || 420));
  isLandscapeMobile(width < (globalAppConfig()?.mobileResponsiveWidth || 550));
  isVeryLargeScreen(
    width > (globalAppConfig()?.veryLargeScreenResponsiveWidth || 1200)
  );

  const [_styles, setStyles] = useState(styles({ height }));

  useEffect(() => {
    appDimensions({ width, height });
  }, [width, height]);

  useEffect(() => {
    setStyles(styles({ width, height }));
  }, [width, height]);

  const handleThemeSelect = (theme) => {
    setMode(theme);
  };

  useEffect(() => {
    const currentURL = window?.location?.href;
    const isMinimalist = currentURL.includes("tf_minimalist");
    if (isMinimalist) {
      handleThemeSelect("blue");
    }
    // check if the windown is an ifram and the parent is toothfairyai.com or toothfairylab.link
    else {
      window?.matchMedia &&
      window?.matchMedia("(prefers-color-scheme: dark)")?.matches
        ? handleThemeSelect("dark")
        : handleThemeSelect("light");
    }
  }, []);

  const mainViewScreen = useMemo(() => {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <MainViewScreen height={height} width={width}>
          {children}
        </MainViewScreen>
      </Suspense>
    );
  }, [width, height, children]);

  return (
    <AppBaseScreen
      backgroundColor={!state?.isChatOpen && currentTheme?.theme?.transparent}
      isScrollable={Platform.OS === "web" && false}
    >
      <View style={_styles.container}>
        <Router>
          {Platform.OS !== "web" && <DeepLinking />}
          {mainViewScreen}
        </Router>
      </View>
    </AppBaseScreen>
  );
};

export default MainViewContainer;

const styles = ({ height, width }) =>
  StyleSheet.create({
    container: {
      height: "100%",
      flexDirection: "row",
      width: "100%",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      overflow: "hidden",
    },
    content: {
      height: height - 46,
      maxHeight: height - 46,
      width: "100%",
      maxWidth: width,
    },
  });
