import { AppIcon, AppText } from "@toothfairy/shared-ui";
import AppQuantumChat from "@toothfairy/shared-ui/AppQuantumChat";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { useEffect, useMemo, useState } from "react";
import { View, useWindowDimensions } from "react-native";
import { appDimensions, isLargeScreen } from "../../MainViewContainer";
import { useHistory } from "../../Router";
import ChatWebInput from "./Components/ChatWebInput";
import ToothairyAIDisclosure from "./Leaf/ToothairyAIDisclosure";

const AgentScreen = ({
  messages,
  setMessages,
  user,
  handleSend = () => {},
  onQuestionPress,
  placeholder = "Ask me anything",
  agentTitle = "ToothFairyAI",
  textToDisplayWhileLoading = "**...**",
  disclaimer = "The information provided might be inaccurate and is not intended to be used for commercial agreements or legal proceedings.",
  isMessagesLoaded,
  languages,
  onLanguageChange = () => {},
  selectedLanguage,
  isAIStreaming,
  showTitle = false,
  onCancel = () => {},
  inputText,
  setInputText,
  loadingMessageId,
  isLoading,
  S3downloadUrlRequest = () => {},
  handleFeedback = () => {},
  agentImage,
  agentColor,
  allowFeedback = false,
  agentLogo,
  allowStop = false,
  isAgentFetched,
  isLastMessageEmpty,
  showDarkMode = true,
  showReloadInDisclosure = true,
  coder = false,
  workspaceId,
  workspace,
  quickQuestions,
  showSplash = false,
  splashTitle,
  logoBackground,
  showTimeForResponse,
  showDetectedLanguage,
  showAgentName = false,
}) => {
  const history = useHistory();
  const { currentTheme, setMode, mode } = AppTheme.useTheme();
  const { width, height } = useWindowDimensions();
  const [outputString, setOutputString] = useState("");
  const [currentCharIndex, setCurrentCharIndex] = useState(0);
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const [composerReady, setComposerReady] = useState(false);
  const chatHeight = height - height / 2.3;
  const extraOffser = !isLargeScreen() ? 70 : 0;
  const isInIframe = window.frameElement !== null;
  const coreHeight = height - 250;
  const [suppressDisabledPlaceholder, setSuppressDisabledPlaceholder] =
    useState(true);
  const _appLogo = useMemo(
    () => (
      <AppIcon
        style={{
          paddingVertical: 10,
          paddingRight: 5,
          width: 152,
          marginLeft: 5,
        }}
        imageSource={require("../../assets/logoTitle.png")}
        width={"100%"}
        size={36}
      />
    ),
    []
  );
  useEffect(() => {
    if (isMessagesLoaded && currentCharIndex < placeholder?.length) {
      const timer = setTimeout(() => {
        setOutputString(
          (prevOutput) => prevOutput + placeholder[currentCharIndex]
        );
        setCurrentCharIndex((prevIndex) => prevIndex + 1);
      }, 1);

      return () => clearTimeout(timer);
    } else if (currentCharIndex === placeholder?.length) {
      setComposerReady(true);
      setSuppressDisabledPlaceholder(false);
    }
  }, [placeholder, currentCharIndex, isMessagesLoaded]);
  const _webInput = useMemo(() => {
    return (
      <ChatWebInput
        agentColor={agentColor}
        placeholder={placeholder}
        setText={setInputText}
        isStreaming={isAIStreaming}
        onCancel={onCancel}
        onSend={handleSend}
        allowStop={allowStop}
        isLastMessageEmpty={isLastMessageEmpty}
        quickQuestions={quickQuestions}
        isChatEmpty={showSplash ? messages?.length == 0 : messages?.length == 1}
      />
    );
  }, [
    isAIStreaming,
    allowStop,
    setInputText,
    placeholder,
    agentColor,
    currentTheme?.theme?.primary,
    isLastMessageEmpty,
    quickQuestions,
    messages?.length,
    showSplash,
  ]);
  return (
    <div
      className="tf-chat-widget-container"
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        flex: 1,
        flexDirection: "column",
        backgroundColor: currentTheme?.theme?.backgroundColor,
      }}
    >
      {mode === "blue" ? (
        <div
          className="tf-agent-header"
          style={{
            backgroundColor: "transparent",
            minHeight: 30,
          }}
        >
          <AppText
            style={{
              fontSize: currentTheme?.theme?.largest_font_size,
            }}
            fontWeight="bold"
            wrapperStyle={{
              marginTop: 10,
              alignSelf: appDimensions().width < 860 && "flex-start",
            }}
          >
            {`Talk to ${agentTitle}`}
          </AppText>
        </div>
      ) : (
        <div
          className="tf-agent-header"
          style={{
            backgroundColor: logoBackground || currentTheme?.theme?.lightGrey,
          }}
        >
          {agentLogo ? (
            <img src={agentLogo} className="tf-agent-logo" />
          ) : (
            isAgentFetched && _appLogo
          )}
        </div>
      )}

      {state?.isChatOpen && isInIframe && (
        <div className="tf-agent-close">
          {/* <AppIcon
            onPress={() => {
              dispatch("SET_CHAT_OPEN", false);
            }}
            icon={AppAssets?.icons?.close}
            color={currentTheme?.theme?.red}
            isClickable
            wrapperStyle={{
              cursor: "pointer",
            }}
          /> */}
          {/* <AppIcon
            onPress={() => {
              window.location.reload();
            }}
            icon={AppAssets?.icons?.reload}
            color={currentTheme?.theme?.primary}
            isClickable
            size={14}
            wrapperStyle={{
              cursor: "pointer",
              rotate: mode == "dark" && "180deg",
              marginLeft: 5,
            }}
          />
          <AppIcon
            onPress={() => {}}
            icon={AppAssets?.icons?.minimise}
            color={currentTheme?.theme?.primary}
            isClickable
            wrapperStyle={{
              cursor: "pointer",
            }}
          /> */}
        </div>
      )}

      <div
        style={{
          minHeight: state?.isChatOpen ? 300 : 50,
          width: state?.isChatOpen && "100%",
        }}
        className="tf-chat-widget"
      >
        {!state?.isChatOpen ? (
          <div
            className="tf-agent-popup"
            onClick={() => {
              dispatch("SET_CHAT_OPEN", true);
            }}
          >
            {`Chat with ${agentTitle}`}
          </div>
        ) : (
          <div className="tf-agent">
            <View
              style={{
                flex: 1,
                height: "100%",
                marginTop: 5,
                paddingHorizontal: 5,
              }}
            >
              {/* <View
                  style={{
                    width: appDimensions().width < 1500 && "100%",
                    paddingTop: isMobileScreen() ? 10 : 60,
                    justifyContent: "space-between",
                    backgroundColor:
                      currentTheme?.theme?.website_contrast_background_color_opaque,
                  }}
                >
                  <FaqBadge onQuestionPress={onQuestionPress} />
                  <SettingsBadge
                    languages={languages}
                    onLanguageChange={onLanguageChange}
                    selectedLanguage={selectedLanguage}
                  />
                </View> */}
              <View
                style={{
                  flexDirection: "row",
                  alignSelf: "flex-start",
                }}
              >
                {showTitle && (
                  <AppText
                    style={{
                      fontSize: currentTheme?.theme?.largest_font_size,
                    }}
                    fontWeight="bold"
                    wrapperStyle={{
                      marginTop: 0,
                      alignSelf: appDimensions().width < 860 && "flex-start",
                    }}
                    color={currentTheme?.theme?.primary}
                  >
                    Ask {agentTitle}
                  </AppText>
                )}

                {/* <AgentSettings
                    onLanguageChange={onLanguageChange}
                    onQuestionPress={onQuestionPress}
                    selectedLanguage={selectedLanguage}
                    languages={languages}
                    isLoading={isLoading}
                  /> */}
              </View>

              <div className="tf-chat-container">
                <AppQuantumChat
                  agentDefaultIcon={
                    agentImage || require("../../assets/favicon.png")
                  }
                  customChartingConfig={workspace?.customChartingConfig}
                  coder={coder}
                  isChatEmpty={messages?.length == 1}
                  sendOnEnter
                  renderMarkdown
                  isWidget
                  workspaceid={workspaceId}
                  loadEarlier={false}
                  messages={messages}
                  setMessages={setMessages}
                  onSendEvent={handleSend}
                  user={{
                    id: user?.id,
                    name: user?.username,
                  }}
                  agentColor={agentColor || currentTheme?.theme?.primary}
                  chatStyle={{
                    height: height - 105,
                  }}
                  showSplash={showSplash}
                  showDuration={showTimeForResponse}
                  showLanguage={showDetectedLanguage}
                  splashTitle={splashTitle}
                  inverted={false}
                  showUserAvatar
                  messagesContainerStyle={{
                    marginTop: -10,
                  }}
                  renderAvatarOnTop
                  isDayDisplayed={false}
                  allowFeedback={allowFeedback}
                  handleFeedback={handleFeedback}
                  maxComposerHeight={60}
                  composerHeight={60}
                  sortOrder="asc"
                  placeholderDisabled="The agent is responding..."
                  isComposerDisabled={!composerReady || isAIStreaming}
                  textColor={"darkGrey"}
                  isTyping={true}
                  showIconForGenericUser
                  textToDisplayWhileLoading={textToDisplayWhileLoading}
                  onCancel={onCancel}
                  inputText={inputText}
                  setInputText={setInputText}
                  renderWithVanillaInput={false}
                  onLoadEarlier={() => {}}
                  S3downloadUrlRequest={S3downloadUrlRequest}
                  showAgentName={showAgentName}
                >
                  {_webInput}
                </AppQuantumChat>
              </div>

              <ToothairyAIDisclosure
                showDarkMode={showDarkMode}
                showReload={showReloadInDisclosure}
                agentColor={agentColor}
                disclaimer={
                  disclaimer ||
                  (isAgentFetched
                    ? "This AI agent is powered by ToothFairyAI"
                    : "")
                }
              />
            </View>
          </div>
        )}
      </div>
    </div>
  );
};

export default AgentScreen;
