import API from "@aws-amplify/api";
import {
  createChat,
  createChatMessage,
  updateChatMessage,
  updateChat,
} from "../../../../app/src/graphql/mutations";
import { getAgent, getChat } from "../../../../app/src/graphql/queries";

const createChat_ = async (object, authToken) => {
  return API.graphql({
    query: createChat,
    variables: { input: object },
    authMode: "API_KEY",
    authToken,
  });
};
const getChat_ = async (id) => {
  return API.graphql({
    query: getChat,
    variables: { id },
  });
};
const updateChat_ = async (id, args, authToken) => {
  const original = await getChat_(id);
  let _properties = {};
  args.forEach((item) => {
    _properties[item.property] = item.value;
  });
  return API.graphql({
    query: updateChat,
    variables: {
      input: {
        id,
        _version: original?.data?.getChat?._version,
        ..._properties,
      },
    },
    authMode: "API_KEY",
    authToken,
  });
};

const createChatMessage_ = async (object, authToken) => {
  return API.graphql({
    query: createChatMessage,
    variables: { input: object },
    authMode: "API_KEY",
    authToken,
  });
};

const updateChatMessage_ = async (object, authToken) => {
  return API.graphql({
    query: updateChatMessage,
    variables: { input: object },
    authMode: "API_KEY",
    authToken,
  });
};

const getAgent_ = async (agentId, authToken) => {
  return API.graphql({
    query: getAgent,
    variables: { id: agentId },
    authMode: "API_KEY",
    authToken,
  });
};

export default {
  createChat: createChat_,
  createChatMessage: createChatMessage_,
  updateChatMessage: updateChatMessage_,
  getAgent: getAgent_,
  updateChat: updateChat_,
};
